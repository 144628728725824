import Accordion from "./blocks/accordion";
import Breadcrumb from "./components/breadcrumb";
import Form from "./components/Form";
import GeoAeraMap from "./blocks/geopraphical_area_map";
import GeoMap from "./blocks/geopraphical_map";
import Header from "./components/Header";
import HeaderHP from "./blocks/header_hp";
import HubMedias from "./blocks/hub_medias";
import KeyFigures from "./blocks/key_figures";
import NewsList from "./blocks/news_list";
import PushChildrenHub from "./blocks/push_children_pages";
import PushNews from "./blocks/push_news";
import PushNewsPress from "./blocks/push_news_press";
import Search from "./blocks/search";
import SliderMulti from "./blocks/slider_multi";
import SliderSingle from "./blocks/slider_single";
import SliderVideos from "./blocks/slider_videos";
import Video from "./blocks/video";
import WarningLink from "./components/WarningLink";
// import ContactForm from "./blocks/contact_form";

import Select from "./components/Select";

const main = {
    init: function() {
        Header.init();
        // SliderSingle.init();
        KeyFigures.init();
        SliderMulti.init();
        Video.init();
        Accordion.init();
        Select.init();
        Form.init();
        GeoMap.init();
        PushNewsPress.init();
        PushChildrenHub.init();
        HubMedias.init();
        PushNews.init();
        NewsList.init();
        HeaderHP.init();
        Search.init();
        Breadcrumb.init();
        GeoAeraMap.init();
        WarningLink.init();
        SliderVideos.init(); 
        // ContactForm.init();
    }
};

export default main